import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from 'react';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { noop } from 'lodash';
import { useHistory, Link } from 'react-router-dom';

import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { Spinner, Button } from '@agconnections/grow-ui';
import Toast from 'components/Toast';
import useDebounce from 'hooks/useDebounce';

import { task as taskApi } from 'utilities/api';

import { getLocalStorageApi, STORAGE_KEYS } from 'utilities/localStorage';

import { Context } from 'components/Store';
import EmptyState from 'components/EmptyState';
import TagsModal from 'components/Modals/TagsModal';
import LandingSearch from 'components/LandingSearch';
import DeleteModal from 'components/Modals/DeleteModal';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';

import { FINANCIAL_ACCESSES } from 'utilities/access';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { paths } from 'routes/paths';
import TaskBoard from './components/TaskBoard';
import TaskTable from './components/TaskTable';

import useTasksData from './helpers/useTasksData';
import useWeatherData from './helpers/useWeatherData';

import { TaskProvider } from './components/TaskContext';
import useTaskEllipseOptions from './hooks/useTaskEllipseMenu';
import ImportConnectModal from './Task/components/ImportConnectModal';

const tasksViewStorageApi = getLocalStorageApi(STORAGE_KEYS.TASKS_VIEW, 'list');

const Tasks = () => {
  const defaultTasksView = tasksViewStorageApi.get();
  const [view] = useState(defaultTasksView);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastAlertObject, setToastAlertObject] = useState({
    title: '',
    message: '',
    type: 'success'
  });
  const {
    tasks,
    deleteTask,
    updateTaskStatus,
    loading,
    loadLandingPageTasks,
    updating: updatingTask,
    totalTasks,
    getTaskById
  } = useTasksData();
  const { updateTaskWeather, updating: updatingWeather } = useWeatherData();
  const {
    exportPdfEnglish,
    exportPdfSpanish,
    downloadingPDF
  } = useTaskEllipseOptions();
  const [
    { loggedInUserOrgPermission, loadingOrg, isGlobalCropSeasonLoading },
    dispatch
  ] = useContext(Context);

  const [pageNo, setPageNo] = useState(0);
  const [filters, setFilters] = useState({});
  const [searchText, setSearchText] = useState(null);
  const [financialAccess, setFinancialAccess] = useState();
  const debouncedSearchTerm = useDebounce(searchText, 500);

  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    id: null
  });

  const [openTags, setOpenTags] = useState(false);
  const [taskName, setTaskName] = useState('');
  const [taskNum, setTaskNum] = useState();
  const [allFlags, setAllFlags] = useState([]);
  const [, setError] = useState(null);

  const amplitude = useContext(AmplitudeContext);

  const [rowIndex, setRowIndex] = useState(-1);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [tagsSaving, setTagsSaving] = useState(false);

  const history = useHistory();
  const prevSearchTerm = useRef();
  const prevFilters = useRef();

  useEffect(() => {
    const currentFilters = JSON.stringify(filters);
    const hasFilterChange =
      debouncedSearchTerm !== prevSearchTerm.current ||
      currentFilters !== prevFilters.current;
    const shouldResetPage = hasFilterChange && pageNo !== 0;

    prevSearchTerm.current = debouncedSearchTerm;
    prevFilters.current = currentFilters;

    loadLandingPageTasks({
      page: pageNo,
      searchText: debouncedSearchTerm,
      daterange: filters?.daterange
    });

    if (shouldResetPage) setPageNo(0);
  }, [debouncedSearchTerm, pageNo, filters, loadLandingPageTasks]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchParam = searchParams.get('search');
    if (searchParam) setSearchText(searchParam);
  }, []);

  useEffect(() => {
    const updateUrlSearchParams = () => {
      const searchParams = new URLSearchParams();
      if (debouncedSearchTerm) searchParams.set('search', debouncedSearchTerm);
      history.replace({ search: searchParams.toString() });
    };
    updateUrlSearchParams();
  }, [debouncedSearchTerm, history, filters]);

  useEffect(() => {
    if (
      loggedInUserOrgPermission?.role === 'Full control' &&
      !Object.prototype.hasOwnProperty.call(
        loggedInUserOrgPermission,
        'financialAccess'
      )
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(loggedInUserOrgPermission?.financialAccess);
    }
  }, [loggedInUserOrgPermission]);

  const handleOrgChange = () => {
    loadLandingPageTasks();
    setPageNo(0);
  };

  const handleDateFilterChange = useCallback(e => {
    const daterange = e.target.value;
    setFilters(prev => {
      if (daterange.length === 2) return { ...prev, daterange };
      if (daterange.length === 0) return { ...prev, daterange: [] };
      return prev;
    });
  }, []);

  const showSpinner = () => {
    return loading || tagsSaving || loadingOrg || isGlobalCropSeasonLoading;
  };

  const closeDeleteModal = () => {
    setDeleteModalState({ open: false, id: null });
  };

  const handleDelete = async () => {
    deleteTask(deleteModalState.id);
    closeDeleteModal();
  };

  const handleReturnTags = async e => {
    const taskDataById = await getTaskById(taskNum);
    setTagsSaving(true);
    const body = {
      ...taskDataById,
      applicationStrategy: 'ratearea',
      flags: e
    };
    const { promise } = await taskApi.update(taskNum, body);
    await promise
      .then(() => {
        loadLandingPageTasks();
        setAllFlags(e);
        setTagsSaving(false);
      })
      .catch(catchCancel)
      .catch(err => {
        if (err.response?.data?.validationErrors) {
          setError(`Error saving task: ${err.response.data.message}`);
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
        setTagsSaving(false);
      });
  };

  const handleImportResult = event => {
    if (event.type === 'success') {
      setImportModalOpen(false);
      handleOrgChange();
    }
    setToastAlertObject(prevState => ({ ...prevState, ...event }));
    setIsToastOpen(true);
  };

  const handleDismissToast = () => setIsToastOpen(false);

  const handleAction = (action, task, rowIdx) => {
    setRowIndex(rowIdx);

    const actions = {
      view: () => {
        history.push(`${paths.tasks}/${task.id}/view`, {
          memberRole: loggedInUserOrgPermission?.role,

          memberFinancialAccess: !Object.prototype.hasOwnProperty.call(
            loggedInUserOrgPermission,
            'financialAccess'
          )
            ? 'none'
            : loggedInUserOrgPermission?.financialAccess
        });
      },
      edit: () => {
        history.push(`${paths.tasks}/${task.id}`);
      },
      delete: () => {
        amplitude.sendEventToAmplitude(amplitude.events.epic.Tasks.deleteTask);
        setDeleteModalState({ id: task.id, open: true });
      },
      tag: () => {
        setTaskName(task.name);
        setTaskNum(task.id);
        setAllFlags(
          task.flags.flags?.length > 0 || task.flags.flags !== undefined
            ? task.flags.flags
            : []
        );
        setOpenTags(true);
      },
      exportPdfEnglish,
      exportPdfSpanish,
      duplicate: async () => {
        await dispatch({
          type: 'SET_CONVERT_PLAN',
          payload: { id: task.id }
        });
        history.push(`${paths.tasks}/duplicate`);
      }
    };

    actions[action](task.id);
  };

  const setPermission = () => {
    const role = loggedInUserOrgPermission?.role.toLowerCase();

    const finAccess = FINANCIAL_ACCESSES.WRITE;

    return (
      (role === 'full control' || role === 'admin') && finAccess === 'WRITE'
    );
  };

  const handleToastButton = () => {
    if (toastAlertObject.buttonFooterType === 'INTEGRATION') {
      history.push(paths.integrations);
    }
  };
  return (
    <>
      <Breadcrumb
        onOrganizationSelect={handleOrgChange}
        hideCropSeasonDropdown={false}
        onCropSeasonSelect={handleOrgChange}
      >
        <Breadcrumb.Item title="Tasks" value="All Tasks" isLast />
      </Breadcrumb>

      <div className="flex items-center justify-between">
        <LandingSearch
          id="task-search-input"
          showCropSeason={false}
          name="search_tasks"
          placeholder="Search Tasks"
          searchValue={searchText}
          onChange={event => {
            setSearchText(event.target.value);
          }}
          onChangeLabel={noop}
          onChangeProductType={noop}
          onChangeTaskType={noop}
          onDateRangeChange={handleDateFilterChange}
        />
        <div className="flex items-center">
          <div className="pl-2 whitespace-no-wrap">
            {setPermission() && (
              <Link to={`${paths.tasks}/create`}>
                <Button
                  id="add-task-button"
                  type="primary"
                  icon={<AddRoundedIcon />}
                >
                  Create Task
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>

      {showSpinner() ? (
        <Spinner />
      ) : (
        <TaskProvider
          updatingTask={updatingTask}
          updatingWeather={updatingWeather}
        >
          <>
            {tasks && tasks.length ? (
              <div>
                <DeleteModal
                  open={deleteModalState.open}
                  itemType="Task"
                  onCancel={() => closeDeleteModal()}
                  onDelete={handleDelete}
                />
                <TagsModal
                  open={openTags}
                  close={() => {
                    setOpenTags(false);
                  }}
                  name={taskName}
                  selectedTags={allFlags?.reduce((tagIds, tag) => {
                    return `${tagIds},${tag.id}`;
                  }, '')}
                  returnSelectedTags={e => handleReturnTags(e)}
                />
                {view === 'board' ? (
                  <TaskBoard
                    tasks={tasks}
                    updateTask={updateTaskStatus}
                    loadLandingPageTasks={loadLandingPageTasks}
                    setTagsSaving={setTagsSaving}
                    memberRole={loggedInUserOrgPermission?.role}
                    memberFinancialAccess={financialAccess}
                    updateTaskWeather={updateTaskWeather}
                    handleAction={handleAction}
                  />
                ) : (
                  <TaskTable
                    tasks={tasks}
                    setTagsSaving={setTagsSaving}
                    memberRole={loggedInUserOrgPermission?.role}
                    memberFinancialAccess={financialAccess}
                    handleAction={handleAction}
                    totalTasks={totalTasks}
                    taskPageNumber={pageNo}
                    setTaskPageNumber={setPageNo}
                    downloadingPDF={downloadingPDF}
                    rowIndexLoading={rowIndex}
                  />
                )}
              </div>
            ) : (
              <EmptyState
                buttonText="Create Task"
                onClick={() => history.push(`${paths.tasks}/create`)}
                subtitle="We were unable to find any tasks under this organization."
                text="Create a New Task."
                setPermission={setPermission}
                // importButtonName="Import From Connect"
                // onImportClick={() => setImportModalOpen(true)}
              />
            )}
            <ImportConnectModal
              isOpen={importModalOpen}
              onCancel={() => setImportModalOpen(false)}
              onImportFinish={handleImportResult}
            />
            <Toast
              type={toastAlertObject.type}
              title={toastAlertObject.title}
              open={isToastOpen}
              onClose={handleDismissToast}
            >
              <p className="text-sm font-body">{toastAlertObject.message}</p>
              <div className="flex mt-4 flex-row-reverse">
                {toastAlertObject.buttonFooterType &&
                  toastAlertObject.buttonFooterType !== 'NONE' && (
                    <>
                      <div className="mr-2">
                        <Button
                          onClick={handleToastButton}
                          danger
                          type="primary"
                        >
                          {toastAlertObject.buttonFooterText}
                        </Button>
                      </div>
                      <div className="mr-2">
                        <Button
                          onClick={handleDismissToast}
                          className="border-0"
                          type="outline"
                          style={{ border: 'none' }}
                        >
                          Dismiss
                        </Button>
                      </div>
                    </>
                  )}
              </div>
            </Toast>
          </>
        </TaskProvider>
      )}
    </>
  );
};

export default Tasks;
