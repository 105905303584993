/* eslint-disable import/prefer-default-export */

const renderLandingTableFullCropHistoryColumns = [
  {
    field: 'cropseason',
    title: `Crop Season`,
    sortable: true,
    width: '197px'
  },
  {
    field: 'cropname',
    title: 'Crop',
    sortable: true,
    width: '197px'
  },
  {
    field: 'variety',
    title: 'Variety',
    sortable: false,
    width: '197px'
  },
  {
    field: 'plantingDate',
    title: 'Date Planted',
    sortable: false,
    width: '197px'
  },
  {
    field: 'area',
    title: 'Area',
    sortable: false,
    width: '197px'
  }
];

const renderLandingTableSummaryCropHistoryColumns = [
  {
    field: 'cropseason',
    title: `Crop Season`,
    sortable: true,
    width: '197px'
  },
  {
    field: 'cropname',
    title: 'Crop',
    sortable: true,
    width: '197px'
  }
];

export const PROPERTY_CROP_HISTORY_TABLE = {
  renderLandingTableSummaryCropHistoryColumns,
  renderLandingTableFullCropHistoryColumns,
  summaryCropHistoryColumnsDefaultSort: {
    by: ['cropseason', 'cropname'],
    dir: ['asc', 'asc']
  },
  fullCropHistoryColumnsDefaultSort: {
    by: ['cropseason', 'cropname', 'variety', 'plantingDate'],
    dir: ['asc', 'asc', 'asc', 'asc']
  }
};
