const reduceVariety = varietyData => {
  const cropSeasonMap = new Map();

  varietyData.slice().forEach(variety => {
    const key = `${variety.cropId}-${variety.cropSeasonId}`;
    if (!cropSeasonMap.has(key)) {
      cropSeasonMap.set(key, {
        cropId: variety.cropId,
        cropSeasonId: variety.cropSeasonId,
        varieties: []
      });
    }
    cropSeasonMap.get(key).varieties.push(variety);
  });

  cropSeasonMap.forEach(({ varieties }) => {
    varieties.forEach(variety => {
      const index = varietyData.indexOf(variety);
      if (index > -1) {
        varietyData.splice(index, 1);
      }
    });
  });

  return Array.from(cropSeasonMap.values());
};

export default reduceVariety;
