import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@agconnections/grow-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { paths } from 'routes/paths';
import FormattedNumber from 'components/FormattedNumber';
import EditIcon from 'assets/edit_outlined.svg';
import RemoveIcon from 'assets/remove_circle_outlined.svg';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { emptyValue } from 'screens/ProductList/helpers/tableConstants';
import getProductQuantity from 'screens/ProductList/helpers/getProductQuantity';
import ProductTypeDisplay from 'screens/ProductList/helpers/ProductTypeDisplay';
import RemoveProductModal from 'screens/ProductList/components/RemoveProductModal';
import useInventoryProduct from 'screens/ProductList/hooks/useInventoryProduct';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Context } from 'components/Store';

const ProductDetailInfo = ({
  product,
  onEdit,
  setProductToRemove,
  setShowRemoveModal,
  showRemoveModal,
  handleRemove,
  toastHasNotBeenClosed,
  toastRenderContents,
  setToastHasNotBeenClosed
}) => {
  const { isRemoveLoading } = useInventoryProduct();

  const [organization] = useContext(Context);
  const {
    feProductListRemoveProduct,
    feProductListRemoveProductButtonByOrgId
  } = useFlags();

  const history = useHistory();

  const handleOnRemove = p => {
    setProductToRemove(p);
    setShowRemoveModal(true);
  };

  const closeRemoveModal = () => {
    setShowRemoveModal(false);
  };

  return (
    <>
      <div className="w-full flex items-center justify-between p-4 bg-white border-b">
        <div className="flex items-center gap-6">
          <Button
            onClick={() => history.push(paths.products)}
            icon={
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="xs"
                style={{ marginRight: '4px' }}
              />
            }
            type="outline"
          >
            Back
          </Button>
          <h1 className="text-2xl font-semibold">{product.productName}</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button
            icon={<img width={18} src={EditIcon} alt="edit icon" />}
            onClick={() => onEdit(product)}
            tabIndex={0}
            type="outline"
          >
            Edit
          </Button>
          {feProductListRemoveProduct ||
          feProductListRemoveProductButtonByOrgId?.includes(
            organization?.organization?.id
          ) ? (
            <Button
              icon={<img width={18} src={RemoveIcon} alt="remove icon" />}
              type="outline"
              onClick={() => {
                handleOnRemove(product.productId);
              }}
            >
              Remove
            </Button>
          ) : null}
          <RemoveProductModal
            open={showRemoveModal}
            itemType="Product"
            close={closeRemoveModal}
            handleRemove={handleRemove}
            isRemoveLoading={isRemoveLoading}
            setToastHasNotBeenClosed={setToastHasNotBeenClosed}
            toastHasNotBeenClosed={toastHasNotBeenClosed}
            toastRenderContents={toastRenderContents}
          />
        </div>
      </div>
      <section className="w-full p-4">
        <div className="flex gap-4">
          <div className="grid items-center w-82 px-4 py-3 bg-white rounded-lg shadow-sm-card">
            <div>
              <span className="text-2xl font-semibold">
                {getProductQuantity(product)}
                <span className="ml-1 text-base font-normal">
                  {getAbbreviatedUnit(product.stdPackageUnit)}
                </span>
              </span>
              <p className="text-base text-neutral-60">
                Total available quantity
              </p>
            </div>
          </div>
          <div className="flex-1 px-4 py-4 bg-white rounded-lg shadow-lg">
            <div className="grid grid-rows-max-content col-gap-20 grid-flow-col auto-cols-max">
              <span className="text-sm text-neutral-60">Manufacturer</span>
              <span className="text-base max-w-xs break-normal">
                {product.custom ? <span>Custom • </span> : null}
                {product.manufacturerName}
              </span>
              <span className="text-sm text-neutral-60">Product Type</span>
              <div className="flex items-start text-base">
                <ProductTypeDisplay
                  productType={product.productType}
                  iconSize={24}
                />
              </div>
              <span className="text-sm text-neutral-60">List Price</span>
              <span className="text-base">
                {product.averagePricePerUnit ? (
                  <>
                    <FormattedNumber unit="usCurrency" roundedTo={2}>
                      {product.averagePricePerUnit}
                    </FormattedNumber>{' '}
                    /{getAbbreviatedUnit(product.stdUnit)}
                  </>
                ) : (
                  emptyValue.none
                )}
              </span>
              {!!product?.packageWeight && (
                <>
                  <span className="text-sm text-neutral">Package Weight</span>
                  <span className="text-base">
                    <FormattedNumber roundedTo={2}>
                      {product.packageWeight}
                    </FormattedNumber>
                    {` / lbs per ${product.stdPackageUnit}`}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ProductDetailInfo.propTypes = {
  product: PropTypes.shape({
    productId: PropTypes.string,
    productName: PropTypes.string,
    productType: PropTypes.string,
    available: PropTypes.number,
    averagePricePerUnit: PropTypes.number,
    averagePriceUnit: PropTypes.string,
    manufacturerName: PropTypes.string,
    custom: PropTypes.bool,
    stdPackageUnit: PropTypes.string,
    packageWeight: PropTypes.number,
    stdUnit: PropTypes.string
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  setProductToRemove: PropTypes.func.isRequired,
  setShowRemoveModal: PropTypes.func.isRequired,
  showRemoveModal: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
  toastHasNotBeenClosed: PropTypes.func.isRequired,
  toastRenderContents: PropTypes.func.isRequired,
  setToastHasNotBeenClosed: PropTypes.func.isRequired
};

export default ProductDetailInfo;
