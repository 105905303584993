/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import NoDocumentIcon from 'assets/no_documents_found.svg';
import LandingTable from 'components/LandingTable';
import CwFPagination from 'components/CwFPagination';
import multiLevelSort from 'helpers/multiLevelSort';
import EmptyTableState from 'components/EmptyTableState';
import HistoryIcon from '../../../../../assets/historyIcon.svg';
import useFieldData from '../../../hooks/useFieldData';
import cropHistoryTableSort from './helpers/cropHistoryTableSort';
import { PROPERTY_CROP_HISTORY_TABLE } from './constants';
import PropertyCardHeader from '../PropertyCardHeader';
import combineCropData from './helpers/combineCropData';
import PropertyCropHistoryFullRow from './components/PropertyCropHistoryFullRow';
import useCropHistoryData from './hooks/useCropHistoryData';
import PropertyCropHistorySummaryRow from './components/PropertyCropHistorySummaryRow';
import reduceVariety from './helpers/reduceVariety';

const {
  summaryCropHistoryColumnsDefaultSort,
  fullCropHistoryColumnsDefaultSort,
  renderLandingTableSummaryCropHistoryColumns,
  renderLandingTableFullCropHistoryColumns
} = PROPERTY_CROP_HISTORY_TABLE;

const PropertyCropHistory = ({ id: fieldId, level = 'field' }) => {
  const isField = level === 'field';

  const [sortField, setSortField] = useState(
    summaryCropHistoryColumnsDefaultSort.by
  );
  const [sortDir, setSortDir] = useState(
    summaryCropHistoryColumnsDefaultSort.dir
  );

  const { field } = useFieldData(fieldId);
  const [combineData, setCombineData] = useState([]);
  const [toggleButtonEnabled, setToggleButtonEnabled] = useState(false);

  const {
    isLoadingCropHistory,
    cropHistoryData,
    isLoadingVariety,
    varietyData,
    resetVariety,
    currentPage,
    setCurrentPage,
    getCropHistory,
    resolvedCropVariety
  } = useCropHistoryData();

  const handlePageChange = page => {
    setToggleButtonEnabled(false);
    resetVariety();
    setCurrentPage(page);
  };

  useEffect(() => {
    if (!toggleButtonEnabled) return;
    resolvedCropVariety(fieldId);
    setCombineData(cropHistoryData.results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleButtonEnabled]);

  useEffect(() => {
    if (
      !cropHistoryData?.results?.length ||
      isLoadingCropHistory ||
      isLoadingVariety
    )
      return;
    setCombineData(
      multiLevelSort({
        items: combineCropData(
          cropHistoryData?.results,
          reduceVariety(varietyData)
        ),
        sortBy: toggleButtonEnabled
          ? fullCropHistoryColumnsDefaultSort.by
          : summaryCropHistoryColumnsDefaultSort.by,
        sortDir: toggleButtonEnabled
          ? fullCropHistoryColumnsDefaultSort.dir
          : summaryCropHistoryColumnsDefaultSort.dir,
        sorter: cropHistoryTableSort
      })
    );
  }, [
    cropHistoryData,
    varietyData,
    isLoadingCropHistory,
    isLoadingVariety,
    toggleButtonEnabled
  ]);

  const handleSort = useCallback((tableField, dir = 'asc') => {
    const newSortBy = [tableField];
    const newSortDir = [dir];
    setSortField(newSortBy);
    setSortDir(newSortDir);
    setCombineData(prevAllData =>
      multiLevelSort({
        items: prevAllData,
        sortBy: newSortBy,
        sortDir: newSortDir,
        sorter: cropHistoryTableSort
      })
    );
  }, []);
  useEffect(() => {
    getCropHistory(currentPage, fieldId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId, currentPage]);

  const isToggleButtonEnabled = () => {
    return toggleButtonEnabled;
  };

  const isCropHistoryLoading = () => {
    return !isLoadingCropHistory;
  };

  const _isLoadingVariety = () => {
    return isLoadingVariety;
  };

  const renderLandingTableSummaryCropHistory = () => (
    <LandingTable
      data-testid="crop-history-table-summary"
      key={currentPage}
      listType="Crop History"
      items={combineData || []}
      tableColumns={renderLandingTableSummaryCropHistoryColumns}
      onSort={handleSort}
      sortBy={sortField[0]}
      sortDir={sortDir[0]}
      wrapperClassName={combineData.length <= 5 ? 'h-full' : 'h-330px'}
    >
      {({ rows }) =>
        rows.map(entry => (
          <PropertyCropHistorySummaryRow
            key={uuidv4()}
            data={entry}
            isField={isField}
          />
        ))
      }
    </LandingTable>
  );

  const renderLandingTableFullCropHistory = () => (
    <div style={{ width: '100%' }}>
      <LandingTable
        data-testid="crop-history-table-full"
        key={currentPage}
        listType="Crop History"
        items={combineData || []}
        tableColumns={renderLandingTableFullCropHistoryColumns}
        onSort={handleSort}
        sortBy={sortField[0]}
        sortDir={sortDir[0]}
        wrapperClassName={combineData.length <= 5 ? 'h-full' : 'h-330px'}
      >
        {({ rows }) =>
          rows.map(entry => (
            <PropertyCropHistoryFullRow
              key={uuidv4()}
              data={entry}
              isField={isField}
            />
          ))
        }
      </LandingTable>
    </div>
  );

  const renderTableContent = () => {
    if (_isLoadingVariety()) {
      return (
        <div className="flex flex-col">
          <p className="">
            <Spinner />
          </p>
          <span className="text-center font-semibold text-base pt-4">
            Loading...
          </span>
        </div>
      );
    }

    if (!isToggleButtonEnabled()) {
      return renderLandingTableSummaryCropHistory();
    }

    return renderLandingTableFullCropHistory();
  };

  const renderContent = () => {
    if (isCropHistoryLoading()) {
      return combineData.length > 0 ? (
        <div
          className={`${
            isToggleButtonEnabled()
              ? 'cwf-property-sticky-table'
              : 'cwf-property-not-sticky'
          } min-w-content`}
        >
          {renderTableContent()}
          {CwFPagination(
            cropHistoryData.totalElements,
            currentPage,
            handlePageChange,
            undefined,
            undefined,
            toggleButtonEnabled,
            setToggleButtonEnabled
          )}
        </div>
      ) : (
        <EmptyTableState
          title="No Crop History Found"
          overlayIcon={NoDocumentIcon}
        />
      );
    }

    return <Spinner />;
  };

  return (
    <div>
      <PropertyCardHeader
        title="Crop History"
        description={`All crops ever planted on field ${field?.name}`}
        icon={HistoryIcon}
      />
      {renderContent()}
    </div>
  );
};
PropertyCropHistory.propTypes = {
  id: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired
};

export default PropertyCropHistory;
