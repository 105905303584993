import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { customDateFormat } from 'helpers/dateFormat';

import defaultIcon from 'assets/AllCropSVGs/fallow.svg';
import CropIcons from 'helpers/getCropIconByCropName';
import CropIconMapping from 'components/CropIconMapping';
import FieldIcon from '../../../../../components/FieldIcon';
import toCamelCase from '../../helpers/toCamelCase';
import {
  calculateHeightForCrop,
  calculateHeightForCropZones,
  calculateHeightForPlantingDate
} from '../../helpers/calculateHeights';

const PropertyCropHistoryFullRow = ({ data, isField }) => {
  const [heights, setHeights] = useState([]);
  const empty = '---';
  const regex = / /g;
  const scrubbedProduct = {
    ...data,
    name: data?.cropname?.replace(regex, '')
  };
  const cropName = scrubbedProduct?.name?.includes(':')
    ? scrubbedProduct?.name
        .split(':')
        .map(part => toCamelCase(part.trim()))
        .join('')
    : scrubbedProduct?.name;

  useEffect(() => {
    if (data?.varieties) {
      const calculatedHeights = data.varieties.map(v => {
        const height1 = calculateHeightForCropZones(v);
        const height2 = calculateHeightForPlantingDate(v, customDateFormat);
        const height3 = calculateHeightForCrop(v);
        return Math.max(height1, height2, height3);
      });
      setHeights(calculatedHeights);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, customDateFormat]);
  return (
    <Table.Row className="bg-white">
      <Table.Cell>{data?.cropseason ?? empty}</Table.Cell>
      <Table.Cell>
        <div className="flex gap-1">
          {cropName in CropIcons ? (
            <CropIconMapping cropObject={scrubbedProduct} />
          ) : (
            <img src={defaultIcon} alt="" />
          )}
          {data?.cropname}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className={data?.varieties ? 'flex flex-col w-full h-full' : null}>
          {data?.varieties?.map(v => {
            return (
              <div
                key={`${v.plantingDate}${v.area}`}
                className="flex flex-row items-center"
                style={{
                  minHeight: heights[0]
                }}
              >
                {v.variety}
              </div>
            );
          }) ?? empty}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className={data?.varieties ? 'flex flex-col w-full h-full' : null}>
          {data?.varieties?.map(v => {
            return (
              <div
                key={`${v.plantingDate}${v.area}`}
                className="flex flex-row items-center"
                style={{
                  minHeight: heights[0]
                }}
              >
                {customDateFormat(v?.plantingDate, 'MMM dd, yyyy')}
              </div>
            );
          }) ?? empty}
        </div>
      </Table.Cell>
      <Table.Cell>
        {isField ? (
          <div>
            {data?.varieties?.map(v => {
              return (
                <div
                  key={`${v.plantingDate}${v.area}`}
                  className="flex flex-col w-full h-full pt-2 pb-2"
                  style={{ minHeight: heights[0] }}
                >
                  {v?.cropZones?.length > 0
                    ? v?.cropZones?.map((cropZone, index) =>
                        index <= 2 ? (
                          <div
                            key={cropZone?.id}
                            className="flex gap-1 items-start"
                          >
                            <FieldIcon
                              id="field-card-icon"
                              width={18}
                              height={18}
                              features={[
                                { geometry: cropZone.geometry, type: 'Feature' }
                              ]}
                            />
                            <div>{cropZone.name}</div>
                          </div>
                        ) : (
                          <div key={cropZone?.productId}>
                            + {v?.cropZones?.length - 3} more crop zones
                          </div>
                        )
                      )
                    : null}
                  <div className="text-xs text-neutral-60 text-left">
                    {`${v?.area} ac`}
                  </div>
                </div>
              );
            }) ?? empty}
          </div>
        ) : null}
      </Table.Cell>
    </Table.Row>
  );
};

PropertyCropHistoryFullRow.propTypes = {
  data: PropTypes.shape({
    cropname: PropTypes.string.isRequired,
    cropseason: PropTypes.string.isRequired,
    varieties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        area: PropTypes.number.isRequired,
        cropZones: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            geometry: PropTypes.objectOf(PropTypes.object)
          })
        ).isRequired
      })
    ).isRequired
  }).isRequired,
  isField: PropTypes.bool.isRequired
};

export default PropertyCropHistoryFullRow;
