import React, { useEffect, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import {
  Input,
  Select,
  RadioButtonGroup,
  DatePicker
} from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import { Context } from 'components/Store';
import { useParams } from 'react-router-dom';
import useWeatherData from '../../../../helpers/useWeatherData';
import { skyConditions, windDirections, soilMoistures } from './weatherData';
import { getFeaturesCentroidCoordinates } from '../../../../../Property/helpers/mapApiHelpers';

const Weather = ({ index, fieldsAndAreasGeoJSONCollection }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { id } = useParams();

  const { RadioButton } = RadioButtonGroup;
  const [context] = useContext(Context);
  const { weatherAutomaticToggle } = context;
  const {
    fetchClearAppWeatherDataReturn,
    fetchURLDetails,
    checkForValidWindDirection,
    DefaultWeatherData
  } = useWeatherData();
  const [shouldPopulateData, setShouldPopulateData] = useState(
    weatherAutomaticToggle === true && values?.startDate !== ''
  );
  // fieldsAndAreasCentroidCoordinates are default coordinates -> might be updated later.
  const [coordinates, setCoordinates] = useState([]);

  const formatDate = date => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    return date !== ''
      ? new Date(date).toLocaleDateString('en-US', options)
      : date;
  };

  const fillWeatherDataFromApi = (
    firstHourlyResult,
    startDateTimeStamp,
    isComplete,
    dateChanged
  ) => {
    return {
      date: new Date(startDateTimeStamp * 1000),
      temperature:
        isComplete || dateChanged
          ? firstHourlyResult?.air_temp?.value
          : values?.weather[index].temperature,
      temperatureUnit:
        isComplete || dateChanged
          ? firstHourlyResult?.air_temp?.unit
          : values?.weather[index]?.temperatureUnit,
      humidity:
        isComplete || dateChanged
          ? firstHourlyResult?.relative_humidity?.value
          : values?.weather[index].humidity,
      skyCondition:
        isComplete || dateChanged
          ? firstHourlyResult?.descriptors?.weather_descriptor?.text
          : values?.weather[index]?.skyCondition,
      windSpeed:
        isComplete || dateChanged
          ? firstHourlyResult?.wind_speed?.value
          : values?.weather[index]?.windSpeed,
      windSpeedUnit:
        isComplete || dateChanged
          ? firstHourlyResult?.wind_speed?.unit?.toUpperCase()
          : values?.weather[index]?.windSpeedUnit,
      windDirection:
        isComplete || dateChanged
          ? checkForValidWindDirection(firstHourlyResult)
          : values?.weather[index]?.windDirection,
      soilMoisture: values?.weather[index]?.soilMoisture.toLowerCase(),
      type: index === 0 ? 'start' : 'end'
    };
  };

  const updateWeather = useCallback(
    async (firstHourlyResult, startDateTimeStamp) => {
      const isComplete = values?.status === 'complete';
      const taskDate = index === 0 ? values?.startDate : values?.endDate;
      const weatherDate = values?.weather[index]?.date;
      const dateChanged =
        weatherDate &&
        new Date(weatherDate).getTime() !== new Date(taskDate).getTime();

      const weatherData = firstHourlyResult
        ? fillWeatherDataFromApi(
            firstHourlyResult,
            startDateTimeStamp,
            isComplete,
            dateChanged
          )
        : {
            ...DefaultWeatherData[index],
            date: new Date(startDateTimeStamp * 1000)
          };
      setFieldValue(`weather[${index}]`, { ...weatherData });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    // Resets lat&long coordinates when user selects fields from properties tab by calculating centroid using respective features.
    if (
      (id === 'create' || id === 'convert' || id === 'duplicate') &&
      values?.properties?.features?.length > 0
    ) {
      const allFeatures = values?.properties?.map(
        property => property.features[0]
      );
      const featureCentroidCoordinates = getFeaturesCentroidCoordinates(
        allFeatures
      );
      setCoordinates([...featureCentroidCoordinates]);
    } else {
      const tempData = values?.properties?.map(property =>
        fieldsAndAreasGeoJSONCollection?.features?.find(
          feature =>
            property.farmId === feature.properties.propertyId &&
            feature.properties.$landType === 'field'
        )
      );
      if (
        tempData.length > 0 &&
        fieldsAndAreasGeoJSONCollection?.features?.length > 0
      ) {
        const featureCentroidCoordinates = getFeaturesCentroidCoordinates(
          tempData
        );
        setCoordinates([...featureCentroidCoordinates]);
      }
    }
  }, [fieldsAndAreasGeoJSONCollection?.features, values?.properties, id]);

  useEffect(() => {
    // Populates weather data in start & end weather sections when user selects Start Date&Time (from task-info section) and toggles button to true.
    setShouldPopulateData(
      weatherAutomaticToggle === true && values?.startDate !== ''
    );
  }, [weatherAutomaticToggle, values?.startDate]);

  useEffect(() => {
    // Fetches data when the user changes a date, task's duration, or fields from the properties tab.
    const fetchData = async () => {
      const currentCheckDate = new Date(new Date(Date.now())).getTime();
      let dueDateEpoch = 0;
      if (values?.duration === 'Custom') {
        dueDateEpoch =
          new Date(values?.dueDate).getTime() || values?.dueDateEpoch;
      } else {
        dueDateEpoch =
          new Date(values?.startDate).getTime() + values?.duration * 3600000;
      }
      const startOrEndDate =
        index === 0 ? new Date(values?.startDate).getTime() : dueDateEpoch;
      if (
        coordinates.length > 0 &&
        startOrEndDate < currentCheckDate &&
        values?.status === 'complete' &&
        weatherAutomaticToggle
      ) {
        const urlDetails = fetchURLDetails(
          new Date(values?.startDate),
          startOrEndDate,
          values?.duration,
          index,
          id,
          new Date(values?.dueDate)
        );
        const weatherData = await fetchClearAppWeatherDataReturn(
          new Date(startOrEndDate),
          coordinates,
          urlDetails
        );
        updateWeather(weatherData, urlDetails.startDateTimeStamp);
      }
      /* 
      // COMMENTING THIS OUT BECAUSE WE DO NOT WANT TO REMOVE EXISTING VALUES IN CASE OF EDIT SCREEN
      // ANOTHER CONDITION CAN BE ADDED TO SET TO DEFAULT VALUES IN CASE OF CREATE SCREEN
      else {
        const defaultWeather = DefaultWeatherData;
        defaultWeather[index].date = new Date(startOrEndDate);
        setFieldValue(`weather[${index}]`, defaultWeather[index]);
      } */
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    coordinates,
    values?.startDate,
    values?.startDateEpoch,
    values?.endDate,
    values?.endDateEpoch,
    values?.duration,
    values?.status,
    weatherAutomaticToggle,
    id,
    index,
    updateWeather,
    setFieldValue
  ]);

  return (
    <>
      <GrowUIFormField
        label="Date &amp; Time"
        control={DatePicker}
        name={`weather[${index}].date`}
        value={formatDate(values?.weather[index]?.date)}
        dateFormat="F j,  Y, h:i K"
        enableTime
      />
      <div className="flex">
        <div className="mr-4">
          <GrowUIFormField
            label="Temperature"
            control={Input}
            name={`weather[${index}].temperature`}
            value={values?.weather[index]?.temperature}
            onChange={e => {
              if (e.target.value.match(/(\d+)/)) {
                setFieldValue(
                  `weather[${index}].temperature`,
                  e.target.value.match(/(\d+)/)[0]
                );
              }
            }}
            imask={{
              mask: `num{° ${values?.weather[0]?.temperatureUnit}}`,
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  signed: true,
                  radix: '.',
                  mapToRadix: [','],
                  thousandsSeparator: ','
                }
              }
            }}
          />
        </div>
        <div className="flex-1">
          <GrowUIFormField
            selected={values?.weather[index]?.temperatureUnit}
            control={RadioButtonGroup}
            name={`weather[${index}].temperatureUnit`}
            onChange={() => {
              setFieldTouched('temperatureUnit');
            }}
          >
            <GrowUIFormField
              value="F"
              id="F"
              name={`weather[${index}].temperatureUnit`}
              control={RadioButton}
            >
              F
            </GrowUIFormField>
            <GrowUIFormField
              value="C"
              id="C"
              name={`weather[${index}].temperatureUnit`}
              control={RadioButton}
            >
              C
            </GrowUIFormField>
          </GrowUIFormField>
        </div>
      </div>
      <GrowUIFormField
        label="Relative Humidity"
        control={Input}
        name={`weather[${index}].humidity`}
        value={values?.weather[index]?.humidity}
        imask={{
          mask: 'num{%}',
          lazy: false,
          blocks: {
            num: {
              mask: Number,
              radix: '.',
              mapToRadix: [','],
              thousandsSeparator: ',',
              min: 0,
              max: 100
            }
          }
        }}
      />
      <GrowUIFormField
        label="Sky Condition"
        control={Select}
        name={`weather[${index}].skyCondition`}
        items={skyConditions}
        placeholder={
          shouldPopulateData ? values?.weather[index]?.skyCondition : 'Select'
        }
        value={values?.weather[index]?.skyCondition}
      />
      <div className="flex">
        <div className="mr-4">
          <GrowUIFormField
            label="Wind Speed"
            control={Input}
            name={`weather[${index}].windSpeed`}
            value={values?.weather[index]?.windSpeed}
            imask={{
              mask: `num{ ${
                values?.weather[index]?.windSpeedUnit !== 'NONE'
                  ? values?.weather[index]?.windSpeedUnit.toLowerCase()
                  : ''
              }}`,
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  radix: '.',
                  mapToRadix: [','],
                  thousandsSeparator: ',',
                  min: 0
                }
              }
            }}
          />
        </div>
        <div className="flex-1">
          <GrowUIFormField
            control={RadioButtonGroup}
            selected={values?.weather[index]?.windSpeedUnit}
            name={`weather[${index}].windSpeedUnit`}
          >
            <GrowUIFormField
              value="MPH"
              id={`{MPH${index}`}
              name={`weather[${index}].windSpeedUnit`}
              control={RadioButton}
            >
              MPH
            </GrowUIFormField>
            <GrowUIFormField
              value="KMH"
              id={`{KMH${index}`}
              name={`weather[${index}].windSpeedUnit`}
              control={RadioButton}
            >
              KMH
            </GrowUIFormField>
            <GrowUIFormField
              value="NONE"
              id={`{0${index}`}
              name={`weather[${index}].windSpeedUnit`}
              control={RadioButton}
            >
              None
            </GrowUIFormField>
          </GrowUIFormField>
        </div>
      </div>
      <GrowUIFormField
        label="Wind Direction"
        control={Select}
        name={`weather[${index}].windDirection`}
        items={windDirections}
        value={values?.weather[index]?.windDirection}
        placeholder={
          shouldPopulateData ? values?.weather[index]?.windDirection : 'Select'
        }
        onChange={e => {
          setFieldValue(
            `weather[${index}].windDirection`,
            JSON.parse(e.target.value).value
          );
        }}
      />
      <GrowUIFormField
        label="Soil Moisture"
        control={Select}
        name={`weather[${index}].soilMoisture`}
        items={soilMoistures}
        placeholder={values?.weather[index]?.soilMoisture || 'Select'}
        value={values?.weather[index]?.soilMoisture}
        onChange={e => {
          if (!e.target.value.includes(values.weather[0].soilMoisture)) {
            setFieldTouched('soilMoisture');
          }
          setFieldValue(
            `weather[${index}].soilMoisture`,
            JSON.parse(e.target.value).value
          );
        }}
      />
    </>
  );
};

Weather.propTypes = {
  index: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldsAndAreasGeoJSONCollection: PropTypes.object.isRequired
};

export default Weather;
