import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TagCloud, Tag } from '@agconnections/grow-ui';
import Paper from 'components/Paper';
import CwFPagination from 'components/CwFPagination';
import TileCard from 'components/TileCard';
import TileCardField from 'components/TileCardField';
import TileGroup from 'components/TileGroup';
import { RECOMMENDATIONS_LANDING_MENU } from 'utilities/menus';
import { paths } from 'routes/paths';
import groupHelpers from '../../helpers/groupHelpers';
import { getSeasons, getSubtitle } from '../../helpers/list';
import useRecEllipseOptions from '../../hooks/useRecEllipseMenu';

const RecommendationsTileBoard = ({
  recommendations,
  tags,
  pageNo,
  setPageNo,
  totalRecommendations,
  onAction,
  downloadingPDF,
  cropSeasons
}) => {
  const recommendationGroups = groupHelpers.groupRecommendationsByType(
    recommendations
  );

  const { filterMenuItems } = useRecEllipseOptions();

  const [gpIndex, setGpIndex] = useState(-1);
  const [tileIndex, setTileIndex] = useState(-1);

  const MAX_TAGS_SHOWN = 3;

  const trimFlags = (
    recommendationTagIds,
    allTags,
    maxTagsShown = MAX_TAGS_SHOWN
  ) => {
    const FilterTags = recommendationTagIds.reduce((acc, flagId) => {
      const tag = allTags.find(t => t.id === flagId);
      return tag ? acc.concat([tag]) : acc;
    }, []);

    const trimmedTags = FilterTags.slice(0, maxTagsShown);

    if (FilterTags.length > maxTagsShown) {
      trimmedTags.push({
        id: 'additional-tag',
        name: `+${FilterTags.length - maxTagsShown}`
      });
    }

    return trimmedTags;
  };

  return (
    <div data-testid="recommendations-board">
      <Paper>
        {CwFPagination(totalRecommendations, pageNo, setPageNo)}
        {Object.values(recommendationGroups).map((group, groupIndex) => {
          return group.recommendations.length > 0 ? (
            <TileGroup
              key={group.id}
              title={group.title}
              totalItems={group.recommendations.length}
              textId="recommendations"
            >
              {group.recommendations.map((recommendation, index) => {
                const trimmedFlags = trimFlags(
                  recommendation.flagIds ?? [],
                  tags
                );
                return (
                  <TileCard
                    key={recommendation.id}
                    testId="recommendation"
                    title={recommendation.title}
                    subtitle={getSubtitle(recommendation)}
                    linkTo={`${paths.recommendations}/${recommendation.id}/view`}
                    onAction={action => {
                      setGpIndex(groupIndex);
                      setTileIndex(index);
                      onAction(action, recommendation);
                    }}
                    menuItems={filterMenuItems(RECOMMENDATIONS_LANDING_MENU)}
                    runningProcesses={
                      downloadingPDF &&
                      gpIndex === groupIndex &&
                      tileIndex === index
                    }
                  >
                    {trimmedFlags.length > 0 && (
                      <TagCloud>
                        {trimmedFlags.map(tag => (
                          <Tag key={tag.id} color={tag.color}>
                            {tag.name}
                          </Tag>
                        ))}
                      </TagCloud>
                    )}
                    <div className="flex flex-wrap w-full">
                      <TileCardField
                        id="field-count"
                        title="Field Count"
                        value={recommendation.cropZones.length}
                        testId="recommendation-field-count"
                      />
                      <TileCardField
                        id="total-acres"
                        title="Total Acres"
                        value={recommendation.cropZones
                          .reduce((acc, zone) => {
                            return acc + zone.areaValue;
                          }, 0)
                          .toFixed(2)}
                        testId="recommendation-total-acres"
                      />
                      <TileCardField
                        id="product-count"
                        title="Product Count"
                        value={
                          recommendation.recommendationProductEntries.length
                        }
                        testId="recommendation-product-count"
                      />
                      <TileCardField
                        id="seasons"
                        title="Seasons"
                        value={getSeasons({
                          seasons: cropSeasons,
                          seasonIds: recommendation.cropSeasonIds
                        })}
                        testId="recommendation-seasons"
                      />
                    </div>
                  </TileCard>
                );
              })}
            </TileGroup>
          ) : null;
        })}
        <div style={{ minHeight: '35px' }}>
          {CwFPagination(totalRecommendations, pageNo, setPageNo)}
        </div>
      </Paper>
    </div>
  );
};

RecommendationsTileBoard.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  ).isRequired,
  cropSeasons: PropTypes.objectOf(PropTypes.object).isRequired,
  onAction: PropTypes.func.isRequired,
  pageNo: PropTypes.number.isRequired,
  setPageNo: PropTypes.func.isRequired,
  totalRecommendations: PropTypes.number.isRequired,
  downloadingPDF: PropTypes.bool
};

RecommendationsTileBoard.defaultProps = {
  downloadingPDF: false
};

export default RecommendationsTileBoard;
