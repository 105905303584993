import axios from 'axios';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import PAGINATION_SIZE from 'helpers/globalConstants';
import { useContext, useState } from 'react';
import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';

const useCropHistoryData = () => {
  const [{ organization }, dispatch] = useContext(Context);
  const [isLoadingCropHistory, setIsLoadingCropHistory] = useState(false);
  const [cropHistoryData, setCropHistoryData] = useState([]);
  const [isLoadingVariety, setIsLoadingVariety] = useState(false);
  const [varietyData, setVarietyData] = useState([{}]);
  const [currentPage, setCurrentPage] = useState(0);

  const getCropVariety = (seasonId, cropId, fieldId) => {
    return axios.get(
      `${CROPWISE_PROXY_URL}/v2/base/field/${fieldId}/crop-varieties?cropSeasonId=${seasonId}&cropId=${cropId}`,
      {
        headers: {
          'cwf-context': JSON.stringify({
            organization_id: organization.id
          }),
          Authorization: `Bearer ${getAccessToken()}`
        }
      }
    );
  };

  const resetVariety = () => {
    setVarietyData([{}]);
  };

  const getCropHistory = async (_currentPage, fieldId) => {
    setIsLoadingCropHistory(true);
    const promise = axios.post(
      `${CROPWISE_PROXY_URL}/v2/base/field/${fieldId}/crophistory?pageNo=${_currentPage}&size=${PAGINATION_SIZE}`,
      {},
      {
        headers: {
          'cwf-context': JSON.stringify({
            organization_id: organization.id
          }),
          Authorization: `Bearer ${getAccessToken()}`
        }
      }
    );

    await promise
      .then(({ data }) => {
        setCropHistoryData(data);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setIsLoadingCropHistory(false);
      });
  };

  const handleFulfilledRequest = request => {
    if (request.status === 'fulfilled') {
      return request.value.data.map(variety => variety);
    }
    return [{}];
  };

  const resolvedCropVariety = fieldId => {
    if (isLoadingCropHistory) return;

    setIsLoadingVariety(true);

    Promise.allSettled(
      cropHistoryData.results.map(({ cropseasonId, cropId }) =>
        getCropVariety(cropseasonId, cropId, fieldId)
      )
    )
      .then(results => {
        const compileResults = results.flatMap(handleFulfilledRequest);
        setVarietyData(compileResults);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingVariety(false));
  };

  return {
    isLoadingCropHistory,
    cropHistoryData,
    isLoadingVariety,
    varietyData,
    currentPage,
    setCurrentPage,
    getCropVariety,
    getCropHistory,
    resolvedCropVariety,
    resetVariety
  };
};

export default useCropHistoryData;
