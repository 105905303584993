import { useContext, useEffect, useState, useCallback } from 'react';
import PAGINATION_SIZE from 'helpers/globalConstants';
import { cloneDeep, findIndex } from 'lodash';

import { tasks as tasksApi, task as taskApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { formatTaskDateFilter } from './dataHelpers';

const useTasksData = () => {
  const taskIdToFetchTask = undefined;
  const [, dispatch] = useContext(Context);
  const [tasks, setTasks] = useState([]);
  const [totalTasks, setTotalTasks] = useState(0);

  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState({});

  const loadLandingPageTasks = useCallback(
    ({ page = 0, searchText = '', daterange = null } = {}) => {
      setLoading(true);
      const { since, until } = formatTaskDateFilter(daterange);
      const selectedSeasons = JSON.parse(
        localStorage.getItem('selectedCropSeasons')
      );
      let seasonsArray = [];
      if (Array.isArray(selectedSeasons)) {
        seasonsArray = selectedSeasons;
      } else {
        seasonsArray.push(selectedSeasons);
      }
      const tasksLandingApi = tasksApi.createChildApi({
        action: 'tasks/landing'
      });
      const { promise } = tasksLandingApi.fetch(taskIdToFetchTask, {
        pageNo: page,
        size: PAGINATION_SIZE,
        seasonIdsCommaSeparated: seasonsArray.join(','),
        searchText,
        since,
        until
      });
      promise
        .then(({ data }) => {
          if (data.results) {
            setTasks(data.results);
            setTotalTasks(data.totalElements);
          }
          setLoading(false);
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taskIdToFetchTask, dispatch]
  );

  const getTaskById = async taskId => {
    try {
      const { promise } = await taskApi.fetch(taskId);
      const tasksResult = await promise;
      return tasksResult?.data || [];
    } catch (error) {
      parseServerError(dispatch)(error);
      return [];
    }
  };

  const updateTaskStatus = async (taskId, status) => {
    setUpdating({
      updating: true,
      taskId
    });
    const body = {
      taskId,
      status
    };
    let tasksCopy = {};
    tasksCopy = cloneDeep(tasks);
    const index = findIndex(tasksCopy, { id: taskId });
    tasksCopy[index].status = status;
    setTasks(tasksCopy);
    const { promise } = await taskApi.update('status', body);
    const result = await promise;
    if (result.status !== 200) {
      loadLandingPageTasks();
    }
    setUpdating({
      updating: false,
      taskId
    });
  };

  const updateTask = async (taskId, task) => {
    setUpdating({
      updating: true,
      taskId
    });
    let tasksCopy = cloneDeep(tasks);

    tasksCopy = tasksCopy.map(taskCopy => {
      if (taskCopy.id === task.id) {
        return task;
      }

      return taskCopy;
    });

    setTasks(tasksCopy);
    const body = {
      id: task.id,
      status: task.status
    };
    await taskApi.update(null, body);
    setUpdating({
      updating: false,
      taskId
    });
  };

  const deleteTask = async taskId => {
    const { promise } = taskApi.delete(taskId);
    const result = await promise;

    const newTasks = tasks.filter(({ id }) => taskId !== id);
    setTasks(newTasks);
    setTotalTasks(newTasks.length);

    if (result.status !== 200) {
      loadLandingPageTasks();
    }
  };

  // Load tasks
  useEffect(() => {
    loadLandingPageTasks();
  }, [loadLandingPageTasks]);

  return {
    updateTask,
    deleteTask,
    updateTaskStatus,
    tasks,
    loading,
    loadLandingPageTasks,
    updating,
    totalTasks,
    getTaskById
  };
};

export default useTasksData;
