export const calculateHeightForCropZones = v => {
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.height = 'auto';
  tempDiv.style.width = '100%';
  tempDiv.className = 'flex flex-col w-full h-full pt-2 pb-2';

  if (v?.cropZones?.length > 0) {
    // eslint-disable-next-line no-unused-expressions
    v?.cropZones?.slice(0, 3).forEach(cropZone => {
      const cropZoneDiv = document.createElement('div');
      cropZoneDiv.className = 'flex gap-1 items-start';
      cropZoneDiv.innerHTML = `
            <div>${cropZone.name}</div>
          `;
      tempDiv.appendChild(cropZoneDiv);
    });

    if (v?.cropZones?.length > 3) {
      const moreDiv = document.createElement('div');
      moreDiv.innerHTML = `+ ${v?.cropZones?.length - 3} more crop zones`;
      tempDiv.appendChild(moreDiv);
    }
  }

  const areaDiv = document.createElement('div');
  areaDiv.className = 'text-xs text-neutral-60 text-left';
  areaDiv.innerHTML = `${v?.area} ac`;
  tempDiv.appendChild(areaDiv);

  document.body.appendChild(tempDiv);
  const height = tempDiv.offsetHeight;
  document.body.removeChild(tempDiv);
  return height;
};

export const calculateHeightForPlantingDate = (v, _customDateFormat) => {
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.height = 'auto';
  tempDiv.style.width = '100%';
  tempDiv.className = 'flex flex-row items-center';
  tempDiv.innerHTML = _customDateFormat(v?.plantingDate, 'MMM dd, yyyy');

  document.body.appendChild(tempDiv);
  const height = tempDiv.offsetHeight;
  document.body.removeChild(tempDiv);

  return height;
};

export const calculateHeightForCrop = v => {
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.height = 'auto';
  tempDiv.style.width = '100%';
  tempDiv.className = 'flex flex-row items-center';
  tempDiv.innerHTML = v.crop;

  document.body.appendChild(tempDiv);
  const height = tempDiv.offsetHeight;
  document.body.removeChild(tempDiv);

  return height;
};
