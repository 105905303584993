import React, { useState, useEffect } from 'react';
import { Input, Select, Toast } from '@agconnections/grow-ui';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext } from 'formik';
import PortalModal from 'components/Modals/PortalModal';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import useProduct from 'hooks/useProduct';
import { Maths } from 'helpers/maths';

const parseCurrencyString = currency =>
  Number(currency.replace(/[^0-9.-]+/g, ''));
// eslint-disable-next-line react/prop-types
const AddProductInvoiceModal = ({
  open,
  onClose,
  product,
  isEdit,
  rowIndex,
  memberRole,
  memberFinancialAccess
}) => {
  const { getMasterListProductUnits, getProductUnits } = useProduct();
  const [stdPackageUnit, setStdPackageUnit] = useState([]);
  const { values, setFieldValue } = useFormikContext();
  const [productToAdd, setProductToAdd] = useState(product);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [unitPrice, setUnitPrice] = useState(
    isEdit
      ? Maths.divideSafeRound(
          product?.totalCostValue,
          product?.totalQuantityValue
        )
      : 0
  );
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    setProductToAdd(product);
  }, [product]);

  useEffect(() => {
    if (open) {
      if (!product?.custom) {
        getMasterListProductUnits(product?.productId).then(setStdPackageUnit);
      } else {
        getProductUnits(product?.productId).then(setStdPackageUnit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, product]);

  const handleClose = () => {
    setFieldValue('totalQuantityUnit', '');
    onClose();
  };

  const calcTotalCost = (totalProduct, _unitPrice) => {
    return Number.parseFloat(totalProduct) * _unitPrice;
  };

  const calcUnitPrice = (totalProduct, totalCostValue) => {
    return totalCostValue / Number.parseFloat(totalProduct);
  };

  const getDisabled = () => {
    if (
      memberRole === 'Full control' &&
      (memberFinancialAccess === 'none' || memberFinancialAccess === 'READ')
    )
      return true;
    return false;
  };

  const displayTotalCostValue = () => {
    if (memberRole === 'Full control' && memberFinancialAccess === 'none') {
      return true;
    }
    if (
      memberRole === 'Full control' &&
      memberFinancialAccess === 'READ' &&
      id === 'invoice'
    ) {
      return true;
    }
    return false;
  };

  const onConfirmHandler = push => {
    const newProduct = {
      ...productToAdd,
      totalQuantityUnit: productToAdd?.totalQuantityUnit,
      timingEvent: values?.timingEvent,
      timingEventTag: values?.timingEventTag,
      totalCostValue: productToAdd?.totalCostValue || 0,
      totalQuantityValue: productToAdd?.totalQuantityValue || 0
    };
    delete newProduct.density;
    if (isEdit) {
      values.invoiceProducts[rowIndex] = newProduct;
    } else {
      push(newProduct);
    }
    handleClose();
  };

  const validateInputs = push => {
    if (productToAdd?.totalQuantityValue > 0) {
      if (productToAdd?.totalCostValue > 0) {
        onConfirmHandler(push);
      } else {
        setOpenConfirmation(true);
      }
    } else {
      setError('Cannot save with Total Product of 0');
    }
  };

  const totalProductValueHandler = e => {
    setProductToAdd({
      ...productToAdd,
      totalQuantityValue: isNaN(e.target.value) ? 0 : e.target.value,
      totalCostValue: calcTotalCost(e.target.value, unitPrice)
    });
  };

  const unitHandler = e => {
    setProductToAdd({
      ...productToAdd,
      totalQuantityUnit: JSON.parse(e.target.value).key
    });
  };

  const unitPriceHandler = e => {
    const newUnitPrice = parseCurrencyString(e.target.value);
    const newTotalCost = calcTotalCost(
      productToAdd?.totalQuantityValue,
      newUnitPrice
    );
    setProductToAdd({
      ...productToAdd,
      totalCostValue: newTotalCost
    });

    setUnitPrice(newUnitPrice);
  };

  const totalCostHandler = e => {
    const newTotalCost = parseCurrencyString(e.target.value);

    const newUnitPrice = calcUnitPrice(
      productToAdd?.totalQuantityValue,
      newTotalCost
    );
    setProductToAdd({
      ...productToAdd,
      totalCostValue: newTotalCost
    });
    setUnitPrice(newUnitPrice);
  };

  return (
    <>
      <FieldArray name="invoiceProducts">
        {({ push }) => (
          <>
            <PortalModal
              open={open}
              close={onClose}
              onCancel={handleClose}
              confirmLabel="Save"
              onConfirm={() => validateInputs(push)}
            >
              <div className="mb-6">
                <div className="flex w-full justify-between">
                  <div
                    data-testid="invoice-product-name"
                    className="font-bold text-lg"
                  >
                    {productToAdd?.productName}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm text-neutral-300">
                    {productToAdd?.manufacturerName}
                  </div>
                </div>
              </div>
              <div className="mt-12">
                <div className="grid grid-cols-9 gap-3 mb-12">
                  <div className="my-2 mb-6 col-span-2">
                    <GrowUIFormField
                      control={Input}
                      label="Total Product"
                      name="totalQuantityValue"
                      value={productToAdd?.totalQuantityValue}
                      onChange={e => totalProductValueHandler(e)}
                    />
                  </div>
                  <div className="my-2 col-span-2">
                    <GrowUIFormField
                      style={{ whiteSpace: 'nowrap' }}
                      control={Select}
                      label="Unit"
                      name="totalQuantityUnit"
                      value={productToAdd?.totalQuantityUnit}
                      items={stdPackageUnit}
                      onChange={e => unitHandler(e)}
                    />
                  </div>
                  <div className="my-2 col-span-2">
                    <GrowUIFormField
                      control={Input}
                      label="Unit Price"
                      name="unitPrice"
                      value={getDisabled() ? null : unitPrice}
                      onBlur={e => unitPriceHandler(e)}
                      maskType="usCurrency"
                      disabled={getDisabled()}
                    />
                  </div>
                  <div className="my-2 col-span-3">
                    <GrowUIFormField
                      control={Input}
                      label="Total Cost"
                      name="totalCost"
                      value={
                        displayTotalCostValue()
                          ? ''
                          : productToAdd?.totalCostValue ?? 0
                      }
                      onBlur={e => totalCostHandler(e)}
                      maskType="usCurrency"
                      disabled={getDisabled()}
                    />
                  </div>
                </div>
              </div>
              {error && (
                <Toast icon="error" onClose={() => setError(null)}>
                  {error}
                </Toast>
              )}
            </PortalModal>
            <PortalModal
              open={openConfirmation}
              close={() => setOpenConfirmation(false)}
              onCancel={() => setOpenConfirmation(false)}
              confirmLabel="Yes"
              onConfirm={() => {
                onConfirmHandler(push);
                setOpenConfirmation(false);
                onClose();
              }}
              type="confirmation"
              title="You are about to save a Product with a price of $0"
            >
              <p>Are you sure you want to save?</p>
            </PortalModal>
          </>
        )}
      </FieldArray>
    </>
  );
};

AddProductInvoiceModal.defaultProps = {
  open: false,
  isEdit: false,
  rowIndex: null
};

AddProductInvoiceModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEdit: PropTypes.bool,
  rowIndex: PropTypes.number,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default AddProductInvoiceModal;
